

































section {
  .header {
    width: 100%;
    height: 100px;
    background-color: #000;
  }
  main {
    width: 1200px;
    margin: 0 auto;
    //padding-top: 100px;

    ::v-deep > div {
      > h3 {
        text-align: center;
        font-size: 28px;
        font-weight: normal;
        color: #000;
        padding: 20px 0;
        border-bottom: 1px solid #f2f2f2;
      }

      > p {
        &:first-of-type {
          margin-top: 30px;
        }

        font-size: 18px;
        color: #666;
        line-height: 36px;
        margin-bottom: 30px;
        text-indent: 2em;
      }

      > strong {
        font-size: 20px;
        line-height: 36px;
        margin-bottom: 10px;
        display: block;
      }
    }
  }
}
@media (max-width: 767px){
  section{
    .header{
      height: 50px;
    }
    main{
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
    }
  }
}
